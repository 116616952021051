<!-- footer start-->
<div class="container-fluid" id="contact">

  <div class="row" style="margin: auto;
  display: flex;
  justify-content: center;">
    <div class="col-xxl-10 col-xl-10 col-lg-12 col-md-12 col-sm-12 col-xs-12">
      <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 m-b-20" style="text-align: center;">
        <h3 class="display-4">Talk To Us</h3>
        <p>Feel free to call, email, or hit us up on our social media accounts.</p>
        <button class="btn btn-primary" type="button" >Contact Us</button>
      </div>
      <div class="row p-r-0" style="justify-content: center;">
        <div class="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-4 col-xs-4">
          <h5>Phone</h5>
          <p>(+63) 917-8123982</p>
        </div>
        <div class="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-4 col-xs-4 section-align-center">
          <h5>Email</h5>
          <p>sales@blockspace.ph</p>
        </div>
        <div class="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-4 col-xs-4" style="
          display: flex;
          gap: 0.5rem;
          flex-wrap: wrap;
          justify-content: flex-end; ">
        <a
          [href]="social_media.path"
          target="_blank"
          *ngFor="let social_media of social_media_menuItems"
        >
          <button
            class="btn btn-pill btn-primary button-back-wallet"
            placement="top"
            ngbTooltip="{{ social_media.title }}"
            style="color: white !important"
          >
            <i
              class="icofont icofont-{{ social_media.icon }}"
              *ngIf="social_media.title != 'Discord'"
            ></i>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              class="bi bi-discord"
              viewBox="0 0 16 16"
              *ngIf="social_media.title == 'Discord'"
            >
              <path
                d="M13.545 2.907a13.227 13.227 0 0 0-3.257-1.011.05.05 0 0 0-.052.025c-.141.25-.297.577-.406.833a12.19 12.19 0 0 0-3.658 0 8.258 8.258 0 0 0-.412-.833.051.051 0 0 0-.052-.025c-1.125.194-2.22.534-3.257 1.011a.041.041 0 0 0-.021.018C.356 6.024-.213 9.047.066 12.032c.001.014.01.028.021.037a13.276 13.276 0 0 0 3.995 2.02.05.05 0 0 0 .056-.019c.308-.42.582-.863.818-1.329a.05.05 0 0 0-.01-.059.051.051 0 0 0-.018-.011 8.875 8.875 0 0 1-1.248-.595.05.05 0 0 1-.02-.066.051.051 0 0 1 .015-.019c.084-.063.168-.129.248-.195a.05.05 0 0 1 .051-.007c2.619 1.196 5.454 1.196 8.041 0a.052.052 0 0 1 .053.007c.08.066.164.132.248.195a.051.051 0 0 1-.004.085 8.254 8.254 0 0 1-1.249.594.05.05 0 0 0-.03.03.052.052 0 0 0 .003.041c.24.465.515.909.817 1.329a.05.05 0 0 0 .056.019 13.235 13.235 0 0 0 4.001-2.02.049.049 0 0 0 .021-.037c.334-3.451-.559-6.449-2.366-9.106a.034.034 0 0 0-.02-.019Zm-8.198 7.307c-.789 0-1.438-.724-1.438-1.612 0-.889.637-1.613 1.438-1.613.807 0 1.45.73 1.438 1.613 0 .888-.637 1.612-1.438 1.612Zm5.316 0c-.788 0-1.438-.724-1.438-1.612 0-.889.637-1.613 1.438-1.613.807 0 1.451.73 1.438 1.613 0 .888-.631 1.612-1.438 1.612Z"
              />
            </svg>
          </button>
        </a>
      </div>
      </div>
    </div>
    <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-md-12 footer-copyright text-center">
      <!-- <button class="btn btn-primary" type="button" ><i class="icofont icofont-social-facebook"></i></button>
      <button class="btn btn-primary" type="button" ><i class="icofont icofont-social-twitter"></i></button>
      <button class="btn btn-primary" type="button" ><i class="icofont icofont-social-instagram"></i></button> -->
   
      <p class="mb-0">Copyright {{ today | date:'y'}} © Blockspace Corporation </p>
    </div>
  </div>
</div>
// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: true,

  // Social Media
  LinkedIn : 'https://www.linkedin.com/company/xgame-official/about/',
  Facebook : 'https://www.facebook.com/official.xgames',
  Youtube : 'https://www.youtube.com/@XGame-mb7cd',
  Discord : 'https://discord.gg/gGqhyzUjZN',
  Twitter : 'https://twitter.com/XGameOfficial_',
  Github : "https://github.com/Blockspace-Corporation/humidefi-parachain-v2.git",
  Instagram: "https://www.instagram.com/officialxgame_/",
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.

import { Component, OnInit } from '@angular/core';
import { Menu, NavService } from '../../services/nav.service';
import { LayoutService } from '../../services/layout.service';
import { NavigationEnd, Router } from '@angular/router';
@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  
  public today: number = Date.now();
  public social_media_menuItems: Menu[];
  constructor(
    private router: Router, 
    public navServices: NavService, 
    public layout: LayoutService
  ) {
    this.navServices.social_media_items.subscribe(social_media_menuItems => {
      this.social_media_menuItems = social_media_menuItems;
    });
   }

  ngOnInit(): void {
    console.log(this.social_media_menuItems);
  }

}
